@import "bootstrap-icons/font/bootstrap-icons.css";/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
h2, span, .primaryFF{
  font-family: Poppins;
}
html, body, .root, app-layout, .page-wrapper { height: 100%!important; margin: 0; padding: 0; }
body { margin: 0; font-family: Poppins, "Helvetica Neue", sans-serif; }

label {
  margin: 0px !important;
}

.cusrsor-p {
  cursor: pointer;
}
/* mat-dialog css starts here */

.mat-mdc-dialog-container {
  padding: 0 0 24px 0 !important;
}
.mdc-dialog__title {
  margin: -43px -1px 20px !important;
  padding: 0 !important;
}
::ng-deep .mat-tab-header {
  border-bottom: 1px solid transparent !important;
}

/*scrollbar-css*/
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b2b2b2;
}

.cursor-pointer {
  cursor: pointer;
}
#side-bar.active .main-content {
  margin-left: 100px !important;
}

#side-bar.active #header {
  padding-left: 125px;
  z-index: 9 !important;
}


#side-bar.active .main-content {
  margin-left: 100px;
}

.main-content-active {
  margin-left: 100px;
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.2s ease;
  transition: margin-left 0.2s ease;
  overflow: hidden;
  /*margin-top: 55px;*/
  background: #66666614;
}

.main-content-active .content-wrapper {
  padding: 0px 30px 0 30px;
  min-height: 100vh;
}
.mat-mdc-dialog-container .mdc-dialog__surface{
  overflow: hidden;
}

@media (max-width: 768px) {
  .main-content-active {
    margin-left: 170px;
    margin-bottom: 0 !important;
    -webkit-transition: margin-left 0.2s ease;
    transition: margin-left 0.2s ease;
    overflow: hidden;
    margin-top: 55px;
    background: #66666614;
  }

  #side-bar .main-content {
    margin-left: 170px !important;
  }

  #side-bar #header {
    padding-left: 100px;
    z-index: 9 !important;
  }

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
